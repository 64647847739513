import UICheckbox from '../../../ui-components/checkbox/UICheckbox';
import UIButton from '../../../ui-components/button/UIButton';
import React, {useState} from 'react';
import './BiopsyComplications.scss';

function BiopsyComplications({complications}) {
  const [checkState, setCheckState] = useState([true, false, true]);

  const changeCheckState = (at, value) => {
    const newState = [...checkState];
    newState[at] = value;
    setCheckState(newState);
  };

  return (
    <div className="patient-biopsy-complications">
      <h2 className="patient-biopsy-complications__subheading">Малки усложнения</h2>
      <div className="patient-biopsy-complications__checkbox-group">
        <UICheckbox checkIsChecked={checkState[0]} change={v => changeCheckState(0, v)} name="smallHematoma" label="Малък хематом"/>
        <UICheckbox checkIsChecked={checkState[1]} change={v => changeCheckState(1, v)} name="singleHematuria" label="Еднократна хематурия"/>
        <UICheckbox checkIsChecked={checkState[2]} change={v => changeCheckState(2, v)} name="painUntilSecondHour" label="Болки до втория час"/>
        <UIButton kind="primary" text="няма" handleClick={() => setCheckState([false, false, false])}/>
      </div>
      <h2 className="patient-biopsy-complications__subheading">Големи усложнения</h2>
      <div className="patient-biopsy-complications__checkbox-group">
        <UICheckbox name="largeHematoma" label="Малък хематом"/>
        <UICheckbox name="massiveHematuria" label="Еднократна хематурия"/>
        <UICheckbox name="moreThan10PercentHGBDrop" label="Болки до втория час"/>
        <UIButton kind="primary" text="няма"/>
      </div>
      <h2 className="patient-biopsy-complications__subheading">Тежки усложнения</h2>
      <div className="patient-biopsy-complications__checkbox-group">
        <UICheckbox name="coagulumInTheBladder" label="Коагулум в пикочния мехур"/>
        <UICheckbox name="nephrectomy" label="Нефректомия"/>
        <UICheckbox name="death" label="Смърт"/>
        <UIButton kind="primary" text="няма"/>
      </div>
      <div className="patient-biopsy-complications__actions">
        <UIButton kind="secondary" text="Откажи"/>
        <UIButton kind="primary" text="Продължи"/>
      </div>
    </div>
  )
}

export default BiopsyComplications;
