import React, {useEffect} from 'react';
import './ViewPatientBiopsy.scss';
import UITabs from '../../ui-components/tabs/UITabs';
import ViewBiopsyResults from './view-biopsy-tabs/ViewBiopsyResults';
import ViewBiopsyComplications from './view-biopsy-tabs/ViewBiopsyComplications';
import ViewBiopsyOther from './view-biopsy-tabs/ViewBiopsyOther';
import ViewBiopsyDetails from './view-biopsy-tabs/ViewBiopsyDetails';
import {connect} from 'react-redux';
import {selectBiopsyByIndex} from '../../../store/viewing-biopsy/viewingBiopsy.actions';
import {fetchCurrentPatientDossier} from '../../../store/current-patient-dossier/currentPatientDossier.actions';

const mapStateToProps = ({viewingBiopsyIndex, currentPatientBiopsies}) => ({
  viewingBiopsy: currentPatientBiopsies[viewingBiopsyIndex]
});


function ViewPatientBiopsy({viewingBiopsy}) {
  return (
    <div className="patient-biopsy">
      <UITabs style="pills" tabLabels={['Показания за ПББ', 'Извършена ПББ', 'Усложнения', 'Други']}>
        <ViewBiopsyResults biopsy={viewingBiopsy}/>
        <ViewBiopsyDetails biopsy={viewingBiopsy}/>
        <ViewBiopsyComplications biopsy={viewingBiopsy}/>
        <ViewBiopsyOther biopsy={viewingBiopsy}/>
      </UITabs>
    </div>
  )
}

export default connect(mapStateToProps)(ViewPatientBiopsy);
