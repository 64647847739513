import React from 'react';
import {connect} from 'react-redux';
import './PatientDetails.scss';
import UIInput from '../../ui-components/input/UIInput';

const mapStateToProps = ({currentPatientDossier}) => ({
  currentPatientDossier,
});

const mapDispatchToProps = {
};

function PatientDetails(props) {
  const {currentPatientDossier} = props;
  return (
    <div className="patient-details">
      <div className="patient-details__ucn">
        <UIInput
            name="ucn"
            label="ЕГН"
            value={currentPatientDossier.ucn}
            readOnly={props.readOnly}
            fullWidth={true}
          />
        </div>
        <div className="patient-details__names">
          <UIInput
            name="firstName"
            label="име"
            value={currentPatientDossier.firstName}
            readOnly={props.readOnly}
          />
          <UIInput
            name="middleName"
            label="презиме"
            value={currentPatientDossier.middleName}
            readOnly={props.readOnly}
          />
          <UIInput
            name="familyName"
            label="фамилия"
            value={currentPatientDossier.familyName}
            readOnly={props.readOnly}
          />
        </div>
        <div className="patient-details__other">
          <UIInput
            name="sex"
            label="пол"
            value={currentPatientDossier.sex}
            readOnly={props.readOnly}
          />
          <UIInput
            name="age"
            label="възраст"
            value={currentPatientDossier.age}
            readOnly={props.readOnly}
          />
          <UIInput
            name="telephone"
            label="телефон"
            value={currentPatientDossier.telephone}
            readOnly={props.readOnly}
          />
        </div>
      </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails);
