import React from 'react';
import './UISearchInput.scss';
import nameToId from '../../../utils/nameToId';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

/* Accepts props: id, type (opt.), label, handleChange, error */
function UISearchInput(props) {
  return (
    <div className="ui-search-input">
      <input
        id={nameToId(props.name)}
        name={props.name}
        type="text"
        value={props.value || ''}
        placeholder={props.placeholder}
        onChange={props.handleChange}
      />
    </div>
  )
}

export default UISearchInput;
