export const CREATE_BLANK_BIOPSY = 'CREATE_BLANK_BIOPSY';
export const EDIT_EXISTING_BIOPSY = 'EDIT_EXISTING_BIOPSY';
export const SUBMIT_BIOPSY = 'SUBMIT_BIOPSY';

export const createBlankBiopsy = () => ({
  type: CREATE_BLANK_BIOPSY
});

export const editExistingBiopsy = (biopsy) => ({
  type: EDIT_EXISTING_BIOPSY,
  biopsy
});

export const submitBiopsy = (biopsy) => ({
  type: SUBMIT_BIOPSY,
  biopsy
});
