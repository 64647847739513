import {SELECT_VIEWING_BIOPSY} from './viewingBiopsy.actions';

const viewingBiopsyReducer = (viewingBiopsyIndex = 0, action) => {
  switch (action.type) {
    case SELECT_VIEWING_BIOPSY:
      console.log(action);
      return action.index;
    default:
      return viewingBiopsyIndex;
  }
};

export default viewingBiopsyReducer;
