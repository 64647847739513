import {CREATE_BLANK_BIOPSY, EDIT_EXISTING_BIOPSY} from './currentlyEditedBiopsy.actions';

const fake = {
  complications: {
    smallHematoma: true,
    singleHematuria: false,
    painUntilSecondHour: true,
    largeHematoma: false,
    massiveHematuria: false,
    moreThan10PercentHGBDrop: false,
    coagulumInTheBladder: false,
    nephrectomy: false,
    death: false
  },
};

const currentlyEditedBiopsyReducer = (currentlyEditedBiopsy = fake, action) => {
  switch (action.type) {
    case CREATE_BLANK_BIOPSY:
      return {};
    case EDIT_EXISTING_BIOPSY:
      return action.biopsy;
    default:
      return currentlyEditedBiopsy;
  }
};

export default currentlyEditedBiopsyReducer;
