import {
  FETCH_CURRENT_PATIENT_BIOPSIES_FAILED,
  FETCH_CURRENT_PATIENT_BIOPSIES_SUCCESSFUL
} from './currentPatientBiopsies.actions';
import mockBiopsies from '../mock-data/mock-biopsies';

const currentPatientBiopsiesReducer = (currentPatientBiopsies = mockBiopsies, action) => {
  switch (action.type) {
    case FETCH_CURRENT_PATIENT_BIOPSIES_SUCCESSFUL:
      return action.biopsies;
    case FETCH_CURRENT_PATIENT_BIOPSIES_FAILED:
      return [];
    default:
      return currentPatientBiopsies;
  }
};

export default currentPatientBiopsiesReducer;
