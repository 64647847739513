const inputValidators = {
  fieldRequired: {
    text: 'Полето е задължително.',
    check: function(userInput) {
      if(!userInput) {
        return 'Полето е задължително.';
      }
    }
  },
  length: {
    text: 'Полето трябва да съдържа точно 10 цифри.',
    check: function(userInput) {
      if(userInput.length !== 10) {
        return true;
      }
    }
  },
  numbersOnly: {
    text: 'Полето трябва да съдържа само цифри.',
    check: function(userInput) {
      let regexp = /\D/g;
      if (regexp.test(userInput)) {
        return true;
      }
    }
  },
  cyrillicOnly: {
    text: 'Името трябва да бъде изписано на кирилица.',
    check: function(userInput) {
      let regexp = /^[\u0430-\u044f\s]*$/gi;
      if(!regexp.test(userInput)) {
        return true;
      }
    }
  },
  capitalized: {
    text: 'Името трябва да започва с главна буква.',
    check: function(userInput) {
    }
  },
  email: {
    text: 'Имейлът е изписан неправилно.',
    check: function(userInput) {
    }
  },
  selectRequired: {
    text: 'Моля, изберете една от опциите.',
    check: function(userInput) {

    }
  },
  passwordLength: {
    text: 'Паролата трябва да съдържа минимум 6 символа.',
    check: function(userInput) {

    }
  },
  passwordContainsNumbers: {
    text: 'Паролата трябва да съдържа поне една цифра.',
    check: function(userInput) {

    }
  },
  passwordIdentity: {
    text: 'Въведените пароли не съвпадат.',
    check: function(userInput) {

    }
  }
};

export default inputValidators;
