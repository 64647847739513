export const FETCH_CURRENT_PATIENT_DOSSIER = 'FETCH_CURRENT_PATIENT_DOSSIER';
export const FETCH_CURRENT_PATIENT_DOSSIER_SUCCESSFUL = 'SET_CURRENT_PATIENT_DOSSIER';
export const FETCH_CURRENT_PATIENT_DOSSIER_FAILED = 'FETCH_CURRENT_PATIENT_DOSSIER_FAILED';
export const EDIT_CURRENT_PATIENT_DOSSIER = 'EDIT_CURRENT_PATIENT_DOSSIER';
export const NEW_PATIENT_DOSSIER = 'NEW_PATIENT_DOSSIER';

export const fetchCurrentPatientDossier = (patientId) => ({
  type: FETCH_CURRENT_PATIENT_DOSSIER,
  patientId
});

export const fetchCurrentPatientDossierSuccessful = (patient) => ({
  type: FETCH_CURRENT_PATIENT_DOSSIER_SUCCESSFUL,
  patient
});

export const fetchCurrentPatientDossierFailed = () => ({
  type: FETCH_CURRENT_PATIENT_DOSSIER_FAILED
});

export const editCurrentPatientDossier = (patient) => ({
  type: EDIT_CURRENT_PATIENT_DOSSIER,
  patient
});

export const newPatientDossier = () => ({
  type: NEW_PATIENT_DOSSIER
});

