const mockBiopsies = [
  {
    id: 1,
    results: [
      {
        test: 'hemoglobin',
        label: 'Хемоглобин (HGB)',
        value: '140',
        unit: 'g/L',
        refValues: '135-180'
      },
      {
        test: 'hematocrit',
        label: 'HCT Хематокрит',
        value: '0.42',
        unit: 'L/L',
        refValues: '0.40 - 0.53'
      },
      {
        test: 'rbc',
        label: 'RBC',
        value: '4.9',
        unit: 'T/I',
        refValues: '4.4 - 5.9'
      },
      {
        test: 'plt',
        label: 'PLT',
        value: '150',
        unit: 'G/I',
        refValues: '130 - 420'
      },
      {
        test: 'serumCreatinine',
        label: 'Серумен креатинин',
        value: '98',
        unit: 'µmol/L',
        refValues: '< 106'
      },
      {
        test: 'uricAcid',
        label: 'Пикочна киселина',
        value: '321',
        unit: 'µmol/L',
        refValues: '202 - 416'
      },
      {
        test: 'totalProtein',
        label: 'Общ белтък',
        value: '66',
        unit: 'g/L',
        refValues: '66 - 87'
      },
      {
        test: 'serumAlbumin',
        label: 'Серумен албумин',
        value: '38',
        unit: 'g/L',
        refValues: '35 - 55'
      },
      {
        test: 'creatinineClearance',
        label: 'Клирънс на креатинин',
        value: '7',
        unit: 'mL/min/1.73m2',
        refValues: '5 - 10'
      },
      {
        test: 'prothombinTime',
        label: 'Протомбиново време',
        value: '80',
        unit: '%',
        refValues: '70 - 130'
      },
      {
        test: 'inr',
        label: 'INR',
        value: '2.8',
        unit: '',
        refValues: '2 - 3.5'
      },
      {
        test: 'aptt',
        label: 'APTT',
        value: '25',
        unit: 'sec',
        refValues: '23 - 31.9'
      },
      {
        test: 'urineProtein',
        label: 'Количество на белтък в урината',
        value: '1.012',
        unit: 'g/24h',
        refValues: '1.015 - 1.022'
      },
      {
        test: 'urinarySediment',
        label: 'Уринен седимент',
        value: '3',
        unit: 'клетки',
        refValues: '1 - 15'
      },
      {
        test: 'arterialSystolicPressure',
        label: 'Артериално налягане систолно',
        value: '130',
        unit: 'mmhg',
        refValues: '120 - 129'
      },
      {
        test: 'arterialDiastolicPressure',
        label: 'Артериално налягане диастолно',
        value: '130',
        unit: 'mmhg',
        refValues: '120 - 129'
      },
    ],
    details: {
      hospital: 'Александровска болница',
      medicalPractitionerType: 'специалист',
      doctorNames: 'Николай Шаферски',
      date: new Date(2018,2, 19),
      continuousSonographicControl: true,
      guide: true,
      automaticBiopticalDevice: false,
      biopsyNeedle: '14G',
      kidneyOrigin: 'native',
      numberOfEntries: 3,
      numberOfMaterials: 2,
      materials: [
        'малък', 'голям', 'няма'
      ]
    },
    complications: {
      smallHematoma: false,
      singleHematuria: false,
      painUntilSecondHour: false,
      largeHematoma: false,
      massiveHematuria: false,
      moreThan10PercentHGBDrop: false,
      coagulumInTheBladder: false,
      nephrectomy: false,
      death: false
    },
    other: {
      pathomorphologist: 'Светослав Попов',
      histomorphologicalResult: '',
      otherBiopsyType: ''
    }
  },
  {
    id: 2,
    results: [
      {
        test: 'hemoglobin',
        label: 'Хемоглобин (HGB)',
        value: '142',
        unit: 'g/L',
        refValues: '135-180'
      },
      {
        test: 'hematocrit',
        label: 'HCT Хематокрит',
        value: '0.42',
        unit: 'L/L',
        refValues: '0.40 - 0.53'
      },
      {
        test: 'rbc',
        label: 'RBC',
        value: '4.9',
        unit: 'T/I',
        refValues: '4.4 - 5.9'
      },
      {
        test: 'plt',
        label: 'PLT',
        value: '150',
        unit: 'G/I',
        refValues: '130 - 420'
      },
      {
        test: 'serumCreatinine',
        label: 'Серумен креатинин',
        value: '98',
        unit: 'µmol/L',
        refValues: '< 106'
      },
      {
        test: 'uricAcid',
        label: 'Пикочна киселина',
        value: '321',
        unit: 'µmol/L',
        refValues: '202 - 416'
      },
      {
        test: 'totalProtein',
        label: 'Общ белтък',
        value: '66',
        unit: 'g/L',
        refValues: '66 - 87'
      },
      {
        test: 'serumAlbumin',
        label: 'Серумен албумин',
        value: '38',
        unit: 'g/L',
        refValues: '35 - 55'
      },
      {
        test: 'creatinineClearance',
        label: 'Клирънс на креатинин',
        value: '7',
        unit: 'mL/min/1.73m2',
        refValues: '5 - 10'
      },
      {
        test: 'prothombinTime',
        label: 'Протомбиново време',
        value: '80',
        unit: '%',
        refValues: '70 - 130'
      },
      {
        test: 'inr',
        label: 'INR',
        value: '2.8',
        unit: '',
        refValues: '2 - 3.5'
      },
      {
        test: 'aptt',
        label: 'APTT',
        value: '25',
        unit: 'sec',
        refValues: '23 - 31.9'
      },
      {
        test: 'urineProtein',
        label: 'Количество на белтък в урината',
        value: '1.012',
        unit: 'g/24h',
        refValues: '1.015 - 1.022'
      },
      {
        test: 'urinarySediment',
        label: 'Уринен седимент',
        value: '3',
        unit: 'клетки',
        refValues: '1 - 15'
      },
      {
        test: 'arterialSystolicPressure',
        label: 'Артериално налягане систолно',
        value: '130',
        unit: 'mmhg',
        refValues: '120 - 129'
      },
      {
        test: 'arterialDiastolicPressure',
        label: 'Артериално налягане диастолно',
        value: '130',
        unit: 'mmhg',
        refValues: '120 - 129'
      },
    ],
    details: {
      hospital: 'Александровска болница',
      medicalPractitionerType: 'специалист',
      doctorNames: 'Николай Шаферски',
      date: new Date(2019,4, 20),
      continuousSonographicControl: true,
      guide: true,
      automaticBiopticalDevice: false,
      biopsyNeedle: '14G',
      kidneyOrigin: 'native',
      numberOfEntries: 3,
      numberOfMaterials: 2,
      materials: [
        'малък', 'голям', 'няма'
      ]
    },
    complications: {
      smallHematoma: false,
      singleHematuria: false,
      painUntilSecondHour: false,
      largeHematoma: false,
      massiveHematuria: false,
      moreThan10PercentHGBDrop: false,
      coagulumInTheBladder: false,
      nephrectomy: false,
      death: false
    },
    other: {
      pathomorphologist: 'Светослав Попов',
      histomorphologicalResult: '',
      otherBiopsyType: ''
    }
  },
  {
    id: 3,
    results: [
      {
        test: 'hemoglobin',
        label: 'Хемоглобин (HGB)',
        value: '143',
        unit: 'g/L',
        refValues: '135-180'
      },
      {
        test: 'hematocrit',
        label: 'HCT Хематокрит',
        value: '0.42',
        unit: 'L/L',
        refValues: '0.40 - 0.53'
      },
      {
        test: 'rbc',
        label: 'RBC',
        value: '4.9',
        unit: 'T/I',
        refValues: '4.4 - 5.9'
      },
      {
        test: 'plt',
        label: 'PLT',
        value: '150',
        unit: 'G/I',
        refValues: '130 - 420'
      },
      {
        test: 'serumCreatinine',
        label: 'Серумен креатинин',
        value: '98',
        unit: 'µmol/L',
        refValues: '< 106'
      },
      {
        test: 'uricAcid',
        label: 'Пикочна киселина',
        value: '321',
        unit: 'µmol/L',
        refValues: '202 - 416'
      },
      {
        test: 'totalProtein',
        label: 'Общ белтък',
        value: '66',
        unit: 'g/L',
        refValues: '66 - 87'
      },
      {
        test: 'serumAlbumin',
        label: 'Серумен албумин',
        value: '38',
        unit: 'g/L',
        refValues: '35 - 55'
      },
      {
        test: 'creatinineClearance',
        label: 'Клирънс на креатинин',
        value: '7',
        unit: 'mL/min/1.73m2',
        refValues: '5 - 10'
      },
      {
        test: 'prothombinTime',
        label: 'Протомбиново време',
        value: '80',
        unit: '%',
        refValues: '70 - 130'
      },
      {
        test: 'inr',
        label: 'INR',
        value: '2.8',
        unit: '',
        refValues: '2 - 3.5'
      },
      {
        test: 'aptt',
        label: 'APTT',
        value: '25',
        unit: 'sec',
        refValues: '23 - 31.9'
      },
      {
        test: 'urineProtein',
        label: 'Количество на белтък в урината',
        value: '1.012',
        unit: 'g/24h',
        refValues: '1.015 - 1.022'
      },
      {
        test: 'urinarySediment',
        label: 'Уринен седимент',
        value: '3',
        unit: 'клетки',
        refValues: '1 - 15'
      },
      {
        test: 'arterialSystolicPressure',
        label: 'Артериално налягане систолно',
        value: '130',
        unit: 'mmhg',
        refValues: '120 - 129'
      },
      {
        test: 'arterialDiastolicPressure',
        label: 'Артериално налягане диастолно',
        value: '130',
        unit: 'mmhg',
        refValues: '120 - 129'
      },
    ],
    details: {
      hospital: 'Александровска болница',
      medicalPractitionerType: 'специалист',
      doctorNames: 'Николай Шаферски',
      date: new Date(2019,11, 3),
      continuousSonographicControl: true,
      guide: true,
      automaticBiopticalDevice: false,
      biopsyNeedle: '14G',
      kidneyOrigin: 'native',
      numberOfEntries: 3,
      numberOfMaterials: 2,
      materials: [
        'малък', 'голям', 'няма'
      ]
    },
    complications: {
      smallHematoma: false,
      singleHematuria: false,
      painUntilSecondHour: false,
      largeHematoma: false,
      massiveHematuria: false,
      moreThan10PercentHGBDrop: false,
      coagulumInTheBladder: false,
      nephrectomy: false,
      death: false
    },
    other: {
      pathomorphologist: 'Светослав Попов',
      histomorphologicalResult: '',
      otherBiopsyType: ''
    }
  }
];

export default mockBiopsies;
