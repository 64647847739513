import React, {useState} from 'react';
import './UICheckbox.scss';
import nameToId from '../../../utils/nameToId';

function UICheckbox(props) {
  const [isChecked, setIsChecked] = useState(props.isChecked);

  //FIXME: doesnt handle keyboard input
  if (props.checkIsChecked !== undefined && props.checkIsChecked != isChecked) {
    setIsChecked(props.checkIsChecked)
  }

  const handleClick = e => {
    if (!props.readOnly) {
      setIsChecked(!isChecked);
    } else {
      e.preventDefault();
    }

    if (props.change) {
      props.change(!isChecked);
    }
  };
  function mapPropsToCssClasses() {
    const readOnly = props.readOnly ? ' read-only' : '';
    const checked = isChecked ? ' checked' : '';
    return `ui-checkbox${readOnly}${checked}`;
  }
  return (
    <div className={mapPropsToCssClasses()} onClick={handleClick}>
      <label htmlFor={props.id}>{props.label}</label>
      <input
        id={nameToId(props.name)}
        name={props.name}
        type='checkbox'
        checked={isChecked}
        readOnly={props.readOnly}
      />
    </div>
  )
}

export default UICheckbox;
