import React from 'react';
import UISearchInput from "../../ui-components/search-input/UISearchInput";
import UIButton from "../../ui-components/button/UIButton";
import useForm from "../../../custom-hooks/useForm";
import useDebounce from "../../../custom-hooks/useDebounce";
import './PatientSearch.scss';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {submitPatientSearch} from "../../../store/patient-search/patientSearch.actions";
import {fetchCurrentPatientDossier} from '../../../store/current-patient-dossier/currentPatientDossier.actions';

const mapDispatchToProps = {
  submitSearch: (terms) => submitPatientSearch(terms),
  setCurrentPatient: (patientId) => fetchCurrentPatientDossier(patientId)
};

const mapStateToProps = ({patientSearch}) => ({
  patientSearchResults: patientSearch.searchResults
});

function PatientSearch(props) {
  const validators = {};
  const {handleChange, handleSubmit, values, errors, setIsSubmitting} = useForm(submit, validators);
  const debouncedErrors = useDebounce(errors, 1000);
  const searchResults = props.patientSearchResults;
  function handleNavigationToPatient(patientId) {
    props.setCurrentPatient(patientId);
  }
  const searchResultItems = searchResults.map(result => (
    <tr className="patient-search__result-item" key={result.id}>
      <td><Link to={`/patient-dossier/${result.id}`} onClick={() => handleNavigationToPatient(result.id)}>{result.ucn}</Link></td>
      <td>{result.firstName} {result.middleName} {result.familyName}</td>
    </tr>
  ));
  function submit() {
    props.submitSearch(values.patientSearch);
  }
  return (
    <div className="patient-search">
      <form className="patient-search__form">
        <UISearchInput
          name="patientSearch"
          placeholder="Въведете ЕГН или име и фамилия"
          value={values.patientSearch}
          handleChange={handleChange}
          error={debouncedErrors.patientSearch}
        />
        <div className="patient-search__submit-button">
          <UIButton handleClick={handleSubmit} kind="primary" text="Търси"/>
        </div>
      </form>
      {searchResults.length > 0 && <table className="patient-search__results"><tbody>{searchResultItems}</tbody></table>}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientSearch);
