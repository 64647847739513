import React from 'react';
import './UIInput.scss';
import nameToId from '../../../utils/nameToId';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

/* Accepts props: id, type (opt.), label, handleChange, error */
function UIInput(props) {
  function mapPropsToCssClasses() {
    const readOnly = props.readOnly ? ' read-only' : '';
    const fullWidth = props.fullWidth ? ' full-width' : '';
    const compact = props.compact ? ' compact' : '';
    const noValidate = props.noValidate ? ' no-validate' : '';
    return `ui-input${readOnly}${fullWidth}${compact}${noValidate}`;
  }
  return (
    <div className={mapPropsToCssClasses()}>
      <label htmlFor={props.id}>{props.label}</label>
      <input
        id={nameToId(props.name)}
        name={props.name}
        type={props.type ? props.type : 'text'}
        value={props.value || ''}
        onChange={props.handleChange}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
      />

      <small className={props.error ? "field-error" : "field-error hidden"}><FontAwesomeIcon icon={faExclamationCircle} />{props.error}</small>
    </div>
  )
}

export default UIInput;
