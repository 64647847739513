import {USER_LOG_IN_SUCCESSFUL} from './user.actions';
import mockUsers from "../mock-data/mock-users";

const userReducer = (user = mockUsers[0], action) => {
  switch (action.type) {
    case USER_LOG_IN_SUCCESSFUL:
      return action.user;
    default:
      return user;
    }
};

export default userReducer;
