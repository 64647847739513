export const FETCH_CURRENT_PATIENT_BIOPSIES_SUCCESSFUL = 'FETCH_CURRENT_PATIENT_BIOPSIES_SUCCESSFUL';
export const FETCH_CURRENT_PATIENT_BIOPSIES_FAILED = 'FETCH_CURRENT_PATIENT_BIOPSIES_FAILED';

export const fetchCurrentPatientBiopsiesSuccessful = (biopsies) => ({
  type: FETCH_CURRENT_PATIENT_BIOPSIES_SUCCESSFUL,
  biopsies
});

export const fetchCurrentPatientBiopsiesFailed = () => ({
  type: FETCH_CURRENT_PATIENT_BIOPSIES_FAILED
});
