const resultsFields = [
  {
    test: 'hemoglobin',
    label: 'Хемоглобин (HGB)',
    unit: 'g/L',
  },
  {
    test: 'hematocrit',
    label: 'HCT Хематокрит',
    unit: 'L/L',
  },
  {
    test: 'rbc',
    label: 'RBC',
    unit: 'T/I',
  },
  {
    test: 'plt',
    label: 'PLT',
    unit: 'G/I',
  },
  {
    test: 'serumCreatinine',
    label: 'Серумен креатинин',
    unit: 'µmol/L',
  },
  {
    test: 'uricAcid',
    label: 'Пикочна киселина',
    unit: 'µmol/L',
  },
  {
    test: 'totalProtein',
    label: 'Общ белтък',
    unit: 'g/L',
  },
  {
    test: 'serumAlbumin',
    label: 'Серумен албумин',
    unit: 'g/L',
  },
  {
    test: 'creatinineClearance',
    label: 'Клирънс на креатинин',
    unit: 'mL/min/1.73m2',
  },
  {
    test: 'prothombinTime',
    label: 'Протомбиново време',
    unit: '%',
  },
  {
    test: 'inr',
    label: 'INR',
    unit: '',
  },
  {
    test: 'aptt',
    label: 'APTT',
    unit: 'sec',
  },
  {
    test: 'urineProtein',
    label: 'Количество на белтък в урината',
    unit: 'g/24h',
  },
  {
    test: 'urinarySediment',
    label: 'Уринен седимент',
    unit: 'клетки',
  },
  {
    test: 'arterialSystolicPressure',
    label: 'Артериално налягане систолно',
    unit: 'mmhg',
  },
  {
    test: 'arterialDiastolicPressure',
    label: 'Артериално налягане диастолно',
    unit: 'mmhg',
  },
];

export default resultsFields;

