import {CLEAR_PATIENT_SEARCH, PATIENT_SEARCH_FAILED, PATIENT_SEARCH_SUCCESSFUL} from "./patientSearch.actions";

export const PatientSearchStatus = {
  successful: 'PATIENT_SEARCH_SUCCESSFUL',
  failed: 'PATIENT_SEARCH_FAILED',
  noSearch: 'NO_PATIENT_SEARCH'
};

const initPatientSearchState = {
  status: PatientSearchStatus.noSearch,
  searchResults: [],
};

const patientSearchReducer = (patientSearchState = initPatientSearchState, action) => {
  switch (action.type) {
    case PATIENT_SEARCH_SUCCESSFUL:
      return {
        status: PatientSearchStatus.successful,
        searchResults: action.searchResults
      };
    case PATIENT_SEARCH_FAILED:
      return {
        status: PatientSearchStatus.failed,
        searchResults: []
      };
    case CLEAR_PATIENT_SEARCH:
      return {
        status: PatientSearchStatus.noSearch,
        searchResults: []
      };
    default:
      return patientSearchState;
  }
};

export default patientSearchReducer;
