import React from 'react';
import './Registration.scss';
import UIInput from '../ui-components/input/UIInput';
import {Link, useHistory} from 'react-router-dom';
import UIButton from '../ui-components/button/UIButton';
import UISelect from '../ui-components/select/UISelect';
import useForm from '../../custom-hooks/useForm';
import {connect} from 'react-redux';
import {clearFailedUserLogIn, userLogInAttempt} from '../../store/user/user.actions';

const mapDispatchToProps = {
  tryToLogInUser: (history, credentials) => userLogInAttempt(history, credentials)
};

function Registration({tryToLogInUser}) {

  const medicalPractitionerOptions = [{
      value: 'specialist',
      name: 'Специалист'
    },
    {
      value: 'specialist_trainee',
      name: 'Специализант'
    }];
  const {handleChange, handleSubmit, values, errors} = useForm(submit);
  const history = useHistory();

  function submit() {
    tryToLogInUser(history, {uid: '1231231231', password: 'ivan'});
    history.push('/dashboard');
  }
  return (
    <div className="registration">
      <h1 className="registration__title">Регистър на бъбречните<br /> биопсии в нефрологията</h1>
      <form className="registration__form" onSubmit={handleSubmit}>
        <div className="input-set">
          <UIInput value={values.uniqueIdNumber} name="uniqueIdNumber" label="УИН" handleChange={handleChange}/>
        </div>
        <div className="input-set">
          <UIInput value={values.firstName} name="firstName" label="име" handleChange={handleChange}/>
          <UIInput value={values.familyName} name="familyName" label="фамилия" handleChange={handleChange}/>
          <UIInput value={values.email} name="email" label="e-mail" type="email" handleChange={handleChange}/>
        </div>
        <div className="input-set">
          <UISelect name="medicalPractitionerType" label="лекар" options={medicalPractitionerOptions} handleChange={handleChange}/>
          <UIInput value={values.hospital} name="hospital" label="болница" handleChange={handleChange}/>
          <UIInput value={values.clinic} name="clinic" label="клиника" handleChange={handleChange}/>
        </div>
        <div className="input-set">
          <UIInput value={values.password} name="password" label="парола" type="password" handleChange={handleChange}/>
          <UIInput value={values.passwordRepeat} name="passwordRepeat" label="парола" type="password" handleChange={handleChange}/>
        </div>
        <Link to="/" className="link login-link">Имам регистрация</Link>
        <UIButton kind="primary" text="Регистрирай се" handleCLick={submit}/>
      </form>
    </div>
    );
}

export default connect(null, mapDispatchToProps)(Registration);
