import React, {useState} from 'react';
import './UITabs.scss';

function UITabs(props) {

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const handleClick = index => {
    setActiveTabIndex(index);
    const {tabClick} = props;
    //FIXME: Only the non editable tabs are ones where we want to dispatch. mega hack shit.
    if (tabClick && index > 0) {
      tabClick(index - 1);
    }
  };
  const labels = props.tabLabels.map((label, index) => (
    <li className={activeTabIndex == index ? `ui-tabs__label ${props.style}-style active` : `ui-tabs__label ${props.style}-style`} key={index}><a onClick={() => handleClick(index)}>{label}</a></li>
  ));
  const tabItems = props.children.map((child, index) => (
    <div className={activeTabIndex == index ? `ui-tabs__item ${props.style}-style active` : `ui-tabs__item ${props.style}-style`} key={index}>
      {child}
    </div>
  ));
  return(
    <div className={'ui-tabs'}>
      <ul className={`ui-tabs__labels ${props.style}-style`}>
        {labels}
      </ul>
        {tabItems}
    </div>
  );
}

export default UITabs;
