import React from 'react';
import { connect } from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUserMd} from '@fortawesome/free-solid-svg-icons';
import {userLogOut} from '../../store/user/user.actions';
import UIDropDown from '../ui-components/drop-down/UIDropDown';
import './Header.scss';

const mapStateToProps = ({user}) => ({
  user
});

const mapDispatchToProps = {
  logOut: () => userLogOut()
};

function Header(props) {
  const handleLogOut = e => {
    props.logOut();
  };
  const toggleContent = (
    <div className="user-menu-toggle">
      <p className="user-name">{props.user.firstName} {props.user.familyName}</p>
      <div className="user-icon">
        <FontAwesomeIcon icon={faUserMd} />
      </div>
    </div>
  );
  const dropDownOptions = [
    (<p onClick={handleLogOut}>Изход</p>)
  ];
  return (
    <header className="app-header">
      <UIDropDown toggleContent={toggleContent} items={dropDownOptions}/>
    </header>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
