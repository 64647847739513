import React, {useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import useForm from '../../custom-hooks/useForm';
import useDebounce from '../../custom-hooks/useDebounce';
import inputValidators from '../../input-validation/inputValidators';
import {clearFailedUserLogIn, userLogInAttempt} from '../../store/user/user.actions';

import './Login.scss';
import UIButton from '../ui-components/button/UIButton';
import UIInput from '../ui-components/input/UIInput';
import {LogInStatus} from '../../store/login-status/loginStatus.reducer';

const mapDispatchToProps = {
  tryToLogInUser: (history, credentials) => userLogInAttempt(history, credentials),
  clearFailedLogIn: () => clearFailedUserLogIn()
};

const mapStateToProps = ({logInStatus}) => ({
  logInStatus
});

function Login(props) {
  const {tryToLogInUser, clearFailedLogIn} = props;
  const {logInStatus} = props;
  const validators = {
    uid: [
      inputValidators.fieldRequired,
      inputValidators.numbersOnly,
      inputValidators.length,
    ],
    password: [
      inputValidators.fieldRequired
    ],
  };

  const [credentialsInvalid, setCredentialsInvalid ] = useState(false);
  const {handleChange, handleSubmit, values, errors, setIsSubmitting} = useForm(submit, validators);
  const debouncedErrors = useDebounce(errors, 1000);
  const history = useHistory();

  useEffect(() => {
    if (logInStatus === LogInStatus.failed) {
      setIsSubmitting(false);
      setCredentialsInvalid(true);
    }
  }, [logInStatus]);

  function submit() {
    tryToLogInUser(history, values);
  }

  const handleInput = event => {
    if(credentialsInvalid) {
      setCredentialsInvalid(false);
      clearFailedLogIn();
    }
    handleChange(event);
  };

  return (
    <div className="login">
      <h1 className="login__title">Регистър на бъбречните<br /> биопсии в нефрологията</h1>
      <div className={credentialsInvalid ? "login__auth-error" : "login__auth-error no-errors"}>Грешен УИН или парола.</div>
      <form className="login__form" >
        <UIInput
          name="uid"
          label="УИН"
          value={values.uid}
          handleChange={handleInput}
          error={debouncedErrors.uid}
        />
        <UIInput
          name="password"
          label="парола"
          type="password"
          value={values.password}
          handleChange={handleInput}
          error={debouncedErrors.password}
        />
        <Link to="/registration" className="link registration-link">Нямам регистрация</Link>
        <UIButton handleClick={handleSubmit} kind="primary" text="Вход"/>
      </form>
    </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
