import React from 'react';
import './Dashboard.scss';
import UIButton from "../ui-components/button/UIButton";
import PatientSearch from "./patient-search/PatientSearch";
import Header from '../header/Header';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import {newPatientDossier} from '../../store/current-patient-dossier/currentPatientDossier.actions';

const mapDispatchToProps = {
  newPatientDossier: () => newPatientDossier()
};

function Dashboard(props) {
  const {newPatientDossier} = props;
  const history = useHistory();
  const navigate = () => {
    newPatientDossier();
    history.push('/new-patient-dossier');
  };

  return (
    <div>
      <Header/>
      <div className="dashboard">
        <h1 className="dashboard__title">Търсене на досиета</h1>
        <PatientSearch/>
        <h1 className="dashboard__title">Ново досие</h1>
        <div className="dashboard__new-dossier">
         <UIButton kind="primary" text="Създай ново досие" handleClick={navigate} />
        </div>
      </div>
    </div>
  );
}

export default connect(null, mapDispatchToProps)(Dashboard);
