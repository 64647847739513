import React, {useState} from 'react';
import './UIToggleGroup.scss';

function UIToggleGroup(props) {
  function processValue() {
    return props.options.findIndex(option => option === props.value);
  }
  const [active, setActive] = useState(processValue());

  function handleClick(index) {
    if (props.readOnly !== true) {
      setActive(index);
    }
  }
  return (
    <div className={props.readOnly ? "ui-toggle-group read-only" : "ui-toggle-group"}>
      {
        props.options.map((option, index) => (
          <button
            className={index === active ? 'active' : ''}
            key={option}
            onClick={() => handleClick(index)}
          >
            {option}
          </button>
        ))
      }
    </div>
  );
}

export default UIToggleGroup;
