const mockUsers = [{
    uid: '1231231231',
    password: 'ivan',
    email: 'i.ivanov@isul-hosp.bg',
    firstName: 'Иван',
    familyName: 'Иванов',
    medicalPractitionerType: 'specialist',
    hospital: 'ИСУЛ',
    clinic: 'клиника "Нефрология"'
  },
  {
    uid: '3213213213',
    password: 'petar',
    email: 'p.petrov@aleksandrovska-hosp.bg',
    firstName: 'Петър',
    familyName: 'Петров',
    medicalPractitionerType: 'specialist',
    hospital: 'Александровска болница',
    clinic: 'клиника "Нефрологични заболявания"'
  }];

export default mockUsers;
