import UIInput from '../../../ui-components/input/UIInput';
import UIToggleGroup from '../../../ui-components/toggle-group/UIToggleGroup';
import UITextarea from '../../../ui-components/textarea/UITextarea';
import React from 'react';
import './ViewBiopsyOther.scss'

function ViewBiopsyOther ({biopsy}) {
  return (
    <div className="view-patient-biopsy-other">
      <div className="view-patient-biopsy-other__content">
        <div className="view-patient-biopsy-other__section">
          <div>
            <h2 className="view-patient-biopsy-other__subheading">Патоморфолог</h2>
            <UIInput name="pathomorphologist" label="име и фамилия" readOnly={true}/>
          </div>
          <div>
            <h2 className="view-patient-biopsy-other__subheading">Друг вид бъбречна биопсия</h2>
            <UIToggleGroup options={['трансюгуларна', 'открита', 'ендоскопска']} readOnly={true}/>
          </div>
        </div>
        <div className="view-patient-biopsy-other__section textarea">
          <h2 className="view-patient-biopsy-other__subheading">Хистоморфологичен резултат</h2>
          <UITextarea name='histomorphologist' readOnly={true}/>
        </div>
      </div>
    </div>
  )
}

export default ViewBiopsyOther;
