import {
  CLEAR_FAILED_USER_LOG_IN,
  USER_LOG_IN_FAILED,
  USER_LOG_IN_SUCCESSFUL,
  USER_LOGGED_OUT
} from '../user/user.actions';

export const LogInStatus = {
  successful: 'LOGIN_SUCCESSFUL',
  failed: 'LOGIN_FAILED',
  loggedOut: 'LOGGED_OUT'
};

const initLogInStatus = LogInStatus.successful;

const loginStatusReducer = (logInStatus = initLogInStatus, action) => {
  switch (action.type) {
    case USER_LOG_IN_SUCCESSFUL:
      return LogInStatus.successful;
    case USER_LOG_IN_FAILED:
      return LogInStatus.failed;
    case CLEAR_FAILED_USER_LOG_IN:
      return LogInStatus.loggedOut;
    case USER_LOGGED_OUT:
      return LogInStatus.loggedOut;
    default:
      return logInStatus;
  }
};

export default loginStatusReducer;
