import React, {useRef} from 'react';
import './UIButton.scss';

function UIButton(props) {
    const hidden = props.hidden ? 'hidden' : '';
    const me = useRef(null);
    function handleClick(e) {
      me.current.blur();
      if(props.handleClick) {
        props.handleClick(e);
      }
    }
    return (
        <button ref={me} onClick={handleClick} className={`ui-button ${props.kind} ${hidden}`}>{props.text}</button>
    )
}

export default UIButton;
