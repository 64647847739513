import React, {useState} from 'react';
import './UIDropDown.scss';

/* Accepts props: id, type (opt.), label, handleChange, error */
function UIDropDown(props) {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = e => {
    setIsOpen(!isOpen);
  };
  const dropDownItems = props.items.map((item, index) => (<div className="ui-drop-down__item" key={index}>{item}</div>));
  return (
    <div className="ui-drop-down">
      <button className="ui-drop-down__toggle" onClick={handleToggle}>{props.toggleContent}</button>
      <div className={isOpen ? "ui-drop-down__panel open" : "ui-drop-down__panel closed"}>
        {dropDownItems}
      </div>
    </div>
  )
}

export default UIDropDown;
