const mockPatients = [
  {
    id: 1,
    ucn: '9204286249',
    firstName: 'Димитър',
    middleName: 'Димитров',
    familyName: 'Костов',
    sex: 'мъж',
    age: '27',
    telephone: '359887568340'
  },
  {
    id: 2,
    ucn: '8805042346',
    firstName: 'Милена',
    middleName: 'Иванова',
    familyName: 'Иванова',
    sex: 'жена',
    age: '31',
    telephone: '359888659344'
  },
  {
    id: 3,
    ucn: '4501063433',
    firstName: 'Младен',
    middleName: 'Стаматов',
    familyName: 'Костов',
    sex: 'мъж',
    age: '74',
    telephone: '359888123123'
  }
];

export default mockPatients;
