import {takeEvery, put, all} from 'redux-saga/effects';
import {PATIENT_SEARCH_FAILED, PATIENT_SEARCH_SUCCESSFUL, SUBMIT_PATIENT_SEARCH} from './patientSearch.actions';
import mockPatients from "../mock-data/mock-patients";

function mockSearch(searchTerms) {
  return mockPatients;
}

function* searchPatients(action) {
  try {
    const searchResults = mockSearch(action.searchTerms);
    yield put({type: PATIENT_SEARCH_SUCCESSFUL, searchResults})
  } catch (e) {
    yield put({type: PATIENT_SEARCH_FAILED});
  }
}

function* searchPatientsSaga() {
  yield all([
    takeEvery(SUBMIT_PATIENT_SEARCH, searchPatients)
  ]);
}

export default searchPatientsSaga;
