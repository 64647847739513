import React from 'react';
import './UITextarea.scss';
import nameToId from '../../../utils/nameToId';

/* Accepts props: id, type (opt.), label, handleChange, error */
function UITextarea(props) {
  return (
    <div className={props.readOnly ? "ui-textarea read-only" : "ui-textarea"}>
      <label htmlFor={props.id}>{props.label}</label>
      <textarea
        id={nameToId(props.name)}
        name={props.name}
        onChange={props.handleChange}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
      >
        {props.value}
      </textarea>
    </div>
  )
}

export default UITextarea;
