import React, {useState} from 'react';
import './ViewPatientDossier.scss';
import { connect } from 'react-redux';
import UIButton from '../ui-components/button/UIButton';
import UITabs from '../ui-components/tabs/UITabs';
import PatientDetails from './patient-details/PatientDetails';
import Header from '../header/Header';
import {useHistory} from 'react-router-dom';
import ViewPatientBiopsy from '../patient-biopsy/view-patient-biopsy/ViewPatientBiopsy';
import EditPatientBiopsy from '../patient-biopsy/edit-patient-biopsy/EditPatientBiopsy';
import {selectBiopsyByIndex} from '../../store/viewing-biopsy/viewingBiopsy.actions';

const mapStateToProps = ({currentPatientDossier, currentPatientBiopsies}) => ({
  currentPatientDossier,
  currentPatientBiopsies
});

const mapDispatchToProps = {
  selectBiopsyByIndex
};

function ViewPatientDossier(props) {
  const {currentPatientBiopsies, selectBiopsyByIndex} = props;
  const history = useHistory();
  const navigateNew = () => {
  };

  const navigateEdit = () => {
    history.push('/edit-patient-dossier');
  };
  const biopsyLabels =  currentPatientBiopsies.map(biopsy => `ПББ ${biopsy.details.date.toLocaleDateString()}`);
  const [createBiopsy, setCreateBiopsy] = useState(true);

  //FIXME: get rid of this shit. refactor UI tabs
  let tabContents = [];
  if (createBiopsy) {
    tabContents.push(<EditPatientBiopsy/>);
  }
  tabContents = [...tabContents, ...biopsyLabels.map(() => <ViewPatientBiopsy/>)];

  return(
    <div>
      <Header/>
      <div className="patient-dossier">
        <h1 className="patient-dossier__heading">Досие на пациент</h1>
        <PatientDetails readOnly={true}/>
        <div className="patient-dossier__actions">
          <UIButton kind="primary" text="Създаване на нова ППБ" handleClick={navigateNew}/>
          <UIButton kind="secondary" text="Редактиране на досието" handleClick={navigateEdit}/>
        </div>
        <UITabs children={tabContents} tabClick={selectBiopsyByIndex} style="tabs" tabLabels={createBiopsy ? ['Въвеждане на ПББ', ...biopsyLabels] : biopsyLabels}/>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewPatientDossier);
