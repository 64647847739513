import React from 'react';
import './BiopsyResultsForm.scss';
import resultsFields from '../../biopsyResultFields';
import UIInput from '../../../ui-components/input/UIInput';
import useForm from '../../../../custom-hooks/useForm';
import UIButton from '../../../ui-components/button/UIButton';

function BiopsyResultsForm() {
  const validators = [];
  const {handleChange, handleSubmit, values, errors, setIsSubmitting} = useForm(submit, validators);
  function submit() {
    console.log('submit');
  }
  return (
    <div className="patient-biopsy-results">
      <p className="patient-biopsy-results__warning">
        Попълнете максималните стойности преди биопсията и референтните стойности за лабораторията.
      </p>
      <form>
      <table className="biopsy-results-table">
        <thead>
        <tr>
          <th>
            Изследване
          </th>
          <th>
            Резултат
          </th>
          <th>
            Мерна единица
          </th>
          <th>
            Референтна стойност
          </th>
        </tr>
        </thead>
        <tbody>
        {
          resultsFields.map(field => (
            <tr key={field.test}>
              <td>{field.label}</td>
              <td>
                <UIInput
                  name={`testResult${field.test}`}
                  placeholder='Въведете резултат'
                  value={values[`testResult${field.test}`]}
                  compact={true}
                  handleChange={handleChange}
                /></td>
              <td>{field.unit}</td>
              <td>
                <UIInput
                  name={`testResult${field.test}Ref`}
                  placeholder='Въведете реф. стойност'
                  value={values[`testResult${field.test}Ref`]}
                  compact={true}
                  handleChange={handleChange}
                />
              </td>
            </tr>
          ))
        }
        </tbody>
      </table>
        <div className="patient-biopsy-results__actions">
          <UIButton kind="secondary" text="Откажи"/>
          <UIButton kind="primary" text="Продължи"/>
        </div>
      </form>
    </div>
  )
}

export default BiopsyResultsForm;
