import UICheckbox from '../../../ui-components/checkbox/UICheckbox';
import UIButton from '../../../ui-components/button/UIButton';
import React from 'react';
import './ViewBiopsyComplications.scss';

function ViewBiopsyComplications ({biopsy}) {
  return (
    <div className="view-patient-biopsy-complications">
      <h2 className="view-patient-biopsy-complications__subheading">Малки усложнения</h2>
      <div className="view-patient-biopsy-complications__checkbox-group">
        <UICheckbox readOnly={true} name="smallHematoma" label="Малък хематом"/>
        <UICheckbox readOnly={true} name="singleHematuria" label="Еднократна хематурия"/>
        <UICheckbox readOnly={true} name="painUntilSecondHour" label="Болки до втория час"/>
      </div>
      <h2 className="view-patient-biopsy-complications__subheading">Големи усложнения</h2>
      <div className="view-patient-biopsy-complications__checkbox-group">
        <UICheckbox readOnly={true} name="largeHematoma" label="Малък хематом"/>
        <UICheckbox readOnly={true} name="massiveHematuria" label="Еднократна хематурия"/>
        <UICheckbox readOnly={true} name="moreThan10PercentHGBDrop" label="Болки до втория час"/>
      </div>
      <h2 className="view-patient-biopsy-complications__subheading">Тежки усложнения</h2>
      <div className="view-patient-biopsy-complications__checkbox-group">
        <UICheckbox readOnly={true} name="coagulumInTheBladder" label="Коагулум в пикочния мехур"/>
        <UICheckbox readOnly={true} name="nephrectomy" label="Нефректомия"/>
        <UICheckbox readOnly={true} name="death" label="Смърт"/>
      </div>
    </div>
  )
}

export default ViewBiopsyComplications;
