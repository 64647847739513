export const SUBMIT_PATIENT_SEARCH = 'SUBMIT_PATIENT_SEARCH';
export const PATIENT_SEARCH_SUCCESSFUL = 'PATIENT_SEARCH_SUCCESSFUL';
export const PATIENT_SEARCH_FAILED = 'PATIENT_SEARCH_FAILED';
export const CLEAR_PATIENT_SEARCH = 'CLEAR_PATIENT_SEARCH';

export const submitPatientSearch = (searchTerms) => ({
    type: SUBMIT_PATIENT_SEARCH,
    searchTerms,
  });

export const patientSearchSuccessful = (searchResults) => ({
    type: PATIENT_SEARCH_SUCCESSFUL,
    searchResults,
});

export const patientSearchFailed = () => ({
  type: PATIENT_SEARCH_FAILED,
});

export const clearPatientSearch = () => ({
  type: CLEAR_PATIENT_SEARCH,
});
