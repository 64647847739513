import UIInput from '../../../ui-components/input/UIInput';
import UICheckbox from '../../../ui-components/checkbox/UICheckbox';
import UIToggleGroup from '../../../ui-components/toggle-group/UIToggleGroup';
import React from 'react';
import useForm from '../../../../custom-hooks/useForm';
import './BiopsyDetailsForm.scss';
import UIButton from '../../../ui-components/button/UIButton';
import indexAsLabel from '../../../../utils/indexAsLabel';

function BiopsyDetailsForm() {

  const validators = [];
  const form = useForm(submit, validators); //const {handleChange, handleSubmit, values, errors, setIsSubmitting}
  console.log(form.values.numberOfEntries);
  const biopsyEntries = form.values.numberOfEntries > 0 ? [...Array(parseInt(form.values.numberOfEntries)).keys()].slice(0,3) : [];


  function submit() {
    console.log('submit');
  }

  return <div className="patient-biopsy-details">
    <h2 className="patient-biopsy-details__subheading">Извършил ПББ</h2>
    <div className="patient-biopsy-details__specialist-info">
      <UIInput handleChange={form.handleChange} label='болница' name='hospital' fullWidth={true} noValidate={true}/>
      <UIInput handleChange={form.handleChange} label='лекар' name='medicalPractitionerType' fullWidth={true}
               noValidate={true}/>
      <UIInput handleChange={form.handleChange} label='име и фамилия' name='doctorNames' fullWidth={true}
               noValidate={true}/>
      <UIInput handleChange={form.handleChange} label='дата на извършване на ПББ' name='date' fullWidth={true}
               noValidate={true}/>
    </div>
    <div className="patient-biopsy-details__sonographic-control">
      <UICheckbox name="continuousSonographic" label="Постоянен ехографски контрол"/>
      <UICheckbox name="continuousSonographic" label="Водач"/>
    </div>
    <div className="patient-biopsy-details__bioptic-device">
      <UICheckbox name="continuousSonographic" label="Атоматично биоптично устройство"/>
    </div>
    <div className="patient-biopsy-details__toggle-groups">
      <div>
        <h2 className="patient-biopsy-details__subheading">Игла за биопсията</h2>
        <UIToggleGroup options={['14G', '16G', '18G']}/>
      </div>
      <div>
        <h2 className="patient-biopsy-details__subheading">Произход на бъбрека</h2>
        <UIToggleGroup options={['нативен', 'трансплантиран']}/>
      </div>
    </div>
    <div>
      <UIInput name="numberOfEntries" label="брой влизания" type="number" value={form.values.numberOfEntries}
               handleChange={form.handleChange} noValidate={true}/>
    </div>
    <div className="patient-biopsy-details__enterings-materials">
      {
        biopsyEntries.map((el, index) => (
        <div className="patient-biopsy-details__enterings-materials-item">
          <h2 className="patient-biopsy-details__subheading">Материал от {indexAsLabel[index]} влизане</h2>
          <UIToggleGroup options={['няма', 'малък', 'голям']}/>
        </div>
        ))
      }
      <div className={biopsyEntries.length > 0 ? "patient-biopsy-details__materials-total" : "patient-biopsy-details__materials-total hidden"}>
        <UIInput
          name="materialsInTotal"
          label="общо материали"
          type="number"
          handleChange={form.handleChange}
          compact={true}
          readOnly={true}/>
      </div>
    </div>
    <div className="patient-biopsy-details__actions">
      <UIButton kind="secondary" text="Откажи"/>
      <UIButton kind="primary" text="Продължи"/>
    </div>
  </div>
}

export default BiopsyDetailsForm;
