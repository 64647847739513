import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {LogInStatus} from '../store/login-status/loginStatus.reducer';

const mapStateToProps = ({logInStatus}) => ({
  logInStatus
});

function ProtectedRoute({component: Component, ...rest}) {
  const isLoggedIn = rest.logInStatus === LogInStatus.successful ? true : false;
  return (
    <Route
      {...rest}
      render={props => {
        if (isLoggedIn) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
}

export default connect(mapStateToProps)(ProtectedRoute);
