import { useState, useEffect } from 'react';
import validate from '../input-validation/validate';

const useForm = (callback, validators, incomingValues) => {
  const [values, setValues] = useState(incomingValues || {});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = event => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validators && validators[name]) {
      setErrors({
        ...errors,
        [name]: validate(value, validators[name])
      });
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsSubmitting(true);
  };

  useEffect(() => {
    if(!hasErrors(errors) && isSubmitting) {
      callback();
    }
  }, [errors, isSubmitting]);
  return {
    handleChange,
    handleSubmit,
    values,
    errors,
    setIsSubmitting
  };

  function hasErrors(errors) {
    return Object.keys(errors).find((key) => {
      return errors[key].length > 0;
    });
  }
};

export default useForm;
