import React from 'react';
import './EditPatientDossier.scss';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import UIInput from '../ui-components/input/UIInput';
import UIButton from '../ui-components/button/UIButton';
import useForm from '../../custom-hooks/useForm';
import {editCurrentPatientDossier} from '../../store/current-patient-dossier/currentPatientDossier.actions';
import inputValidators from '../../input-validation/inputValidators';
import Header from '../header/Header';

const mapStateToProps = ({currentPatientDossier}) => ({
  currentPatientDossier,
});

const mapDispatchToProps = {
  editPatient: (patient) => editCurrentPatientDossier(patient)
};

function EditPatientDossier(props) {
  const {currentPatientDossier, editPatient} = props;
  const modeLabel = currentPatientDossier.firstName ? 'Редактиране на досие' : 'Създаване на ново досие'; //FIXME: look at id instead
  const validators = {
    ucn: [
      inputValidators.fieldRequired
    ],
    firstName: [
      inputValidators.fieldRequired
    ],
    middleName: [],
    familyName: [],
    sex: [],
    age: [],
    telephone: [],
  };
  const history = useHistory();
  const submit = () => {
    editPatient(patient);
    navigateOnSubmit();
  };
  const cancel = () => {
    history.goBack();
  };
  const {handleChange, handleSubmit, values: patient, errors, setIsSubmitting} = useForm(submit, validators, currentPatientDossier);
  const navigateOnSubmit = () => {
    history.push('/patient-dossier/1'); //FIXME: use server value, or equivalent
  };


  return (
    <div>
      <Header />
      <div className="edit-patient-dossier">
        <h1 className="edit-patient-dossier__heading">{modeLabel}</h1>
          <div className="edit-patient-dossier__ucn">
            <UIInput
              name="ucn"
              label="ЕГН"
              value={patient.ucn}
              handleChange={handleChange}
              error={errors.ucn}
              fullWidth={true}
            />
          </div>
          <div className="edit-patient-dossier__names">
            <UIInput
              name="firstName"
              label="име"
              value={patient.firstName}
              readOnly={props.readOnly}
              handleChange={handleChange}
              error={errors.firstName}
            />
            <UIInput
              name="middleName"
              label="презиме"
              value={patient.middleName}
              readOnly={props.readOnly}
              handleChange={handleChange}
              error={errors.middleName}
            />
            <UIInput
              name="familyName"
              label="фамилия"
              value={patient.familyName}
              readOnly={props.readOnly}
              handleChange={handleChange}
              error={errors.familyName}
            />
          </div>
          <div className="edit-patient-dossier__other">
            <UIInput
              name="sex"
              label="пол"
              value={patient.sex}
              readOnly={props.readOnly}
              handleChange={handleChange}
              error={errors.sex}
            />
            <UIInput
              name="age"
              label="възраст"
              value={patient.age}
              readOnly={props.readOnly}
              handleChange={handleChange}
              error={errors.age}
            />
            <UIInput
              name="telephone"
              label="телефон"
              value={patient.telephone}
              readOnly={props.readOnly}
              handleChange={handleChange}
              error={errors.telephone}
            />
          </div>
          <div className="edit-patient-dossier__actions">
            <UIButton kind='secondary' hidden={props.readOnly} text={'Откажи'} handleClick={cancel}/>
            <UIButton kind='primary' hidden={props.readOnly} text={'Запиши'} handleClick={handleSubmit}/>
          </div>
        </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPatientDossier);
