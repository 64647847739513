import UIInput from '../../../ui-components/input/UIInput';
import UIToggleGroup from '../../../ui-components/toggle-group/UIToggleGroup';
import UITextarea from '../../../ui-components/textarea/UITextarea';
import React from 'react';
import useForm from '../../../../custom-hooks/useForm';
import './BiopsyOther.scss';
import UIButton from '../../../ui-components/button/UIButton';

function BiopsyOther (props) {
  const validators = [];
  const form = useForm(submit, validators); //const {handleChange, handleSubmit, values, errors, setIsSubmitting}

  function submit() {
    console.log('submit');
  }

  return (
    <div className="patient-biopsy-other">
      <div className="patient-biopsy-other__content">
        <div className="patient-biopsy-other__section">
          <div>
            <h2 className="patient-biopsy-other__subheading">Патоморфолог</h2>
            <UIInput handleChange={form.handleChange} name="pathomorphologist" label="име и фамилия" noValidate={true}/>
          </div>
          <div>
            <h2 className="view-patient-biopsy-other__subheading">Хистоморфологичен резултат</h2>
            <UIToggleGroup options={['трансюгуларна', 'открита', 'ендоскопска']}/>
          </div>
        </div>
        <div className="patient-biopsy-other__section textarea">
          <h2 className="patient-biopsy-other__subheading">Хистоморфологичен резултат</h2>
          <UITextarea name='histomorphologist'/>
        </div>
      </div>
      <div className="patient-biopsy-other__actions">
        <UIButton kind="secondary" text="Откажи"/>
        <UIButton kind="primary" text="Запиши"/>
      </div>
    </div>
  )
}

export default BiopsyOther;
