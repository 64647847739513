import {combineReducers} from 'redux';
import user from './user/user.reducer';
import logInStatus from './login-status/loginStatus.reducer';
import patientSearch from './patient-search/patientSearch.reducer';
import currentPatientDossier from './current-patient-dossier/currentPatientDossier.reducer';
import currentPatientBiopsies from './current-patient-biopsies/currentPatientBiopsies.reducer';
import currentlyEditedBiopsy from './currently-edited-biopsy/currentlyEditedBiopsy.reducer';
import viewingBiopsyIndex from './viewing-biopsy/viewingBiopsy.reducer';

export default combineReducers({
  user,
  logInStatus,
  patientSearch,
  currentPatientDossier,
  currentPatientBiopsies,
  currentlyEditedBiopsy,
  viewingBiopsyIndex
});
