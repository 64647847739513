import {
  EDIT_CURRENT_PATIENT_DOSSIER,
  FETCH_CURRENT_PATIENT_DOSSIER_FAILED,
  FETCH_CURRENT_PATIENT_DOSSIER_SUCCESSFUL, NEW_PATIENT_DOSSIER
} from "./currentPatientDossier.actions";

const currentPatientDossierReducer = (currentPatientDossier = {}, action) => {
  switch (action.type) {
    case FETCH_CURRENT_PATIENT_DOSSIER_SUCCESSFUL:
    case EDIT_CURRENT_PATIENT_DOSSIER:
      return action.patient;
    case FETCH_CURRENT_PATIENT_DOSSIER_FAILED:
    case NEW_PATIENT_DOSSIER:
      return {};
    default:
      return currentPatientDossier;
  }
};

export default currentPatientDossierReducer;
