export const USER_LOG_IN_ATTEMPT = 'USER_LOG_IN_ATTEMPT';
export const USER_LOG_IN_FAILED = 'USER_LOG_IN_FAILED';
export const CLEAR_FAILED_USER_LOG_IN = 'CLEAR_FAILED_USER_LOG_IN';
export const USER_LOG_IN_SUCCESSFUL = 'USER_LOG_IN_SUCCESSFUL';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const USER_CREATED = 'USER_CREATED';

export const userLogInAttempt = (history, credentials) => ({
  type: USER_LOG_IN_ATTEMPT,
  history,
  credentials,
});

export const userLogInFailed = () => ({
  type: USER_LOG_IN_FAILED
});

export const clearFailedUserLogIn = () => ({
  type: CLEAR_FAILED_USER_LOG_IN
});

export const userLogInSuccessful = (user) => ({
  type: USER_LOG_IN_SUCCESSFUL,
  user
});

export const userLogOut = () => ({
  type: USER_LOGGED_OUT,
});

export const createUser = (user) => ({
  type: USER_CREATED,
  user
});
