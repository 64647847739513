import React from 'react';
import './UISelect.scss';
import nameToId from '../../../utils/nameToId';

function UISelect(props) {
  const optionList = props.options.map(({value, name}) => (
    <option value={value} key={name}>{name}</option>
  ));
  return (
    <div className="ui-select">
      <label htmlFor={props.id}>{props.label}</label>
      <select name={props.name} id={nameToId(props.name)} onBlur={props.handleChange}>
        {optionList}
      </select>
    </div>
  )
}

export default UISelect;
