import UIInput from '../../../ui-components/input/UIInput';
import UICheckbox from '../../../ui-components/checkbox/UICheckbox';
import UIToggleGroup from '../../../ui-components/toggle-group/UIToggleGroup';
import React from 'react';
import './ViewBiopsyDetails.scss';

function ViewBiopsyDetails ({biopsy}) {
  return (
    <div className="view-patient-biopsy-details">
      <h2 className="view-patient-biopsy-details__subheading">Извършил ПББ</h2>
      <div className="view-patient-biopsy-details__specialist-info">
        <UIInput readOnly={true} label='болница' name='hospital' value={biopsy.details.hospital} fullWidth={true}/>
        <UIInput readOnly={true} label='лекар' name='medicalPractitionerType' value={biopsy.details.doctorNames} fullWidth={true}/>
        <UIInput readOnly={true} label='име и фамилия' name='doctorNames' value={biopsy.details.doctorNames} fullWidth={true}/>
        <UIInput readOnly={true} label='дата на извършване на ПББ' name='date' value={biopsy.details.date.toLocaleDateString()} fullWidth={true}/>
      </div>
      <div className="view-patient-biopsy-details__sonographic-control">
        <UICheckbox readOnly={true} name="continuousSonographic" label="Постоянен ехографски контрол" isChecked={true}/>
        <UICheckbox readOnly={true} name="continuousSonographic" label="Водач"/>
      </div>
      <div className="view-patient-biopsy-details__bioptic-device">
        <UICheckbox readOnly={true} name="continuousSonographic" label="Атоматично биоптично устройство"/>
      </div>
      <div className="view-patient-biopsy-details__toggle-groups">
        <div>
          <h2 className="view-patient-biopsy-details__subheading">Игла за биопсията</h2>
          <UIToggleGroup options={['14G', '16G', '18G']} value='14G' readOnly={true}/>
        </div>
        <div>
          <h2 className="view-patient-biopsy-details__subheading">Произход на бъбрека</h2>
          <UIToggleGroup options={['нативен', 'трансплантация']} readOnly={true}/>
        </div>
      </div>
      <div>
        <UIInput name="numberOfEntries" label="брой влизания" readOnly={true}/>
      </div>
    </div>
  )
}

export default ViewBiopsyDetails;
