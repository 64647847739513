import {all, takeEvery, call, put} from 'redux-saga/effects';
import {USER_LOG_IN_ATTEMPT, USER_LOG_IN_FAILED, USER_LOG_IN_SUCCESSFUL} from './user.actions';
import axios from 'axios';
import mockUsers from '../mock-data/mock-users';

const delay = (ms) => new Promise(res => setTimeout(res, ms));

function mockLogIn(credentials) {
  const user = mockUsers.find(user => user.uid === credentials.uid);
  if (user.password === credentials.password) {
    return user;
  } else {
    throw new Error('incorrect username or password');
  }
}

function* logIn(action) {
  console.log(action.credentials);
  try {
    // const response = yield axios.post('/login', {...action.user});
    const user = mockLogIn(action.credentials);
    yield put({type: USER_LOG_IN_SUCCESSFUL, user});
    action.history.push('/dashboard');
  } catch (e) {
    yield put({type: USER_LOG_IN_FAILED});
  }
}

function* userSaga() {
  yield all([
    takeEvery(USER_LOG_IN_ATTEMPT, logIn)
  ]);
}

export default userSaga;
