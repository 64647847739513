import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';

function createAppStore() {
  /* TODO: Handle redux devtools */
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(reducers, composeEnhancers(applyMiddleware(sagaMiddleware)));
  sagas.forEach(saga => sagaMiddleware.run(saga));
  return store;
}

export default createAppStore;
