
import {takeEvery, put, all} from 'redux-saga/effects';
import {
  FETCH_CURRENT_PATIENT_BIOPSIES_FAILED,
  FETCH_CURRENT_PATIENT_BIOPSIES_SUCCESSFUL
} from './currentPatientBiopsies.actions';
import {FETCH_CURRENT_PATIENT_DOSSIER} from '../current-patient-dossier/currentPatientDossier.actions';
import mockBiopsies from '../mock-data/mock-biopsies';


function getBiopsiesByPatientId(patientId) {
  return mockBiopsies;
}

function* fetchDossier(action) {
  try {
    const biopsies = getBiopsiesByPatientId(action.patientId);
    yield put({type: FETCH_CURRENT_PATIENT_BIOPSIES_SUCCESSFUL, biopsies})
  } catch (e) {
    yield put({type: FETCH_CURRENT_PATIENT_BIOPSIES_FAILED});
  }
}

function* currentPatientBiopsiesSaga() {
  yield all([
    takeEvery(FETCH_CURRENT_PATIENT_DOSSIER, fetchDossier)
  ]);
}

export default currentPatientBiopsiesSaga;
