import React from 'react';
import './EditPatientBiopsy.scss';
import UITabs from '../../ui-components/tabs/UITabs';
import { connect } from 'react-redux';
import useForm from '../../../custom-hooks/useForm';
import BiopsyResultsForm from './edit-biopsy-tabs/BiopsyResultsForm';
import BiopsyDetailsForm from './edit-biopsy-tabs/BiopsyDetailsForm';
import BiopsyComplications from './edit-biopsy-tabs/BiopsyComplications';
import BiopsyOther from './edit-biopsy-tabs/BiopsyOther';

const mapStateToProps = ({currentlyEditedBiopsy, user}) => ({
  currentlyEditedBiopsy,
  user
});

const mapDispatchToProps = {
};

function EditPatientBiopsy(props) {
  const biopsy = props.currentlyEditedBiopsy;
  const validators = [];
  const {handleChange, handleSubmit, values, errors, setIsSubmitting} = useForm(submit, validators);

  function submit() {
    console.log('submit');
  }
  return (
    <div className="patient-biopsy">
      <UITabs style="pills" tabLabels={['Показания за ПББ', 'Извършена ПББ', 'Усложнения', 'Други']}>
        <BiopsyResultsForm/>
        <BiopsyDetailsForm/>
        <BiopsyComplications complications={biopsy.complications}/>
        <BiopsyOther/>
      </UITabs>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPatientBiopsy);
