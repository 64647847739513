import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import {Route, BrowserRouter, Switch} from 'react-router-dom'
import {Provider} from 'react-redux';
import './App.scss';
import Login from '../login/Login';
import Registration from '../registration/Registration';
import createStore from '../../store/createStore';
import Dashboard from '../dashboard/Dashboard';
import ProtectedRoute from '../../routing/protected.route';
import PatientDossier from "../patient-dossier/ViewPatientDossier";
import NewPatientDossier from '../patient-dossier/EditPatientDossier';

const store = createStore();

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <div className="app standard-theme">
                    <Switch>
                        <Route exact path='/' component={Login}/>
                        <Route path='/registration' component={Registration}/>
                        <ProtectedRoute path='/dashboard' component={Dashboard} />
                        <ProtectedRoute path='/patient-dossier' component={PatientDossier} />
                        <ProtectedRoute path='/new-patient-dossier' component={NewPatientDossier} />
                        <ProtectedRoute path='/edit-patient-dossier' component={NewPatientDossier} />
                    </Switch>
                </div>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
