import UIInput from '../../../ui-components/input/UIInput';
import React from 'react';

function ViewBiopsyResults ({biopsy}) {
  return (
    <div className="patient-biopsy__results">
      <table className="biopsy-results-table">
        <thead>
        <tr>
          <th>
            Изследване
          </th>
          <th>
            Резултат
          </th>
          <th>
            Мерна единица
          </th>
          <th>
            Референтна стойност
          </th>
        </tr>
        </thead>
        <tbody>
        {
          biopsy.results.map(result => (
            <tr key={result.test}>
              <td>{result.label}</td>
              <td>
                <UIInput
                  name={result.test}
                  placeholder='Въведете резултат'
                  value={result.value}
                  readOnly={true}
                  compact={true}
                />
              </td>
              <td>{result.unit}</td>
              <td>
                <UIInput
                  name={`${result.test}Ref`}
                  placeholder='Въведете реф. стойност'
                  value={result.refValues}
                  readOnly={true}
                  compact={true}
                />
              </td>
            </tr>
          ))
        }
        </tbody>
      </table>
    </div>
  )
}

export default ViewBiopsyResults;
